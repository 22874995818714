import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE

export default {
  namespaced: true,
  state: {
    is_loading: false,
    breadcrumbs: [],
    categories: [],
    admin_categories: [],
    category: null,
    parent: null,
    filters: {
      parent_id: null
    },
    show_create_category: false,
    show_edit_category: false,
    show_move_category: false
  },
  mutations: {
    setAdminCategories (state, payload) {
      state.admin_categories = payload
    },
    setCategories (state, payload) {
      state.categories = payload
    },
    setCategory (state, payload) {
      state.category = payload
    },
    setParent (state, category) {
      state.parent = category
      state.filters.parent_id = category ? category.id : null
    },
    setParentId (state, id) {
      state.filters.parent_id = id
    },
    setBreadcrumbs (state, payload) {
      state.breadcrumbs = payload
    },
    setShowCreateCategory (state, v) {
      state.show_create_category = v
    },
    setShowEditCategory (state, v) {
      state.show_edit_category = v
    },
    setShowMoveCategory (state, v) {
      state.show_move_category = v
    }
  },
  actions: {
    async getAdminCatalog ({
      state,
      commit
    }, parentId) {
      state.is_loading = true
      const promise = axios.get(baseURL + '/api/admin/drop-categories', {
        params: {
          parent_id: parentId
        }
      })
      promise.then(response => {
        commit('setAdminCategories', response.data.data)
        state.is_loading = false
      })

      return promise
    },
    async getParents ({
      state,
      commit
    }) {
      return axios.get(baseURL + '/api/admin/drop-categories/parents')
    },
    async getCategory ({
      state,
      commit
    }, id) {
      const promise = axios.get(baseURL + '/api/admin/drop-categories/' + id)
      promise.then(response => {
        commit('setCategory', response.data.data)
      })

      return promise
    },
    async getCatalog ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL + '/api/categories', {
        params: {
          parent_id: state.filters.parent_id,
          per_page: 100
        }
      })
      promise.then(response => {
        commit('setCategories', response.data.data)
        commit('setParent', response.data.parent)
        commit('setBreadcrumbs', response.data.breadcrumbs)
      })

      return promise
    },
    async createCategory ({
      state,
      commit
    }, data) {
      return axios.post(baseURL + '/api/admin/drop-categories/', data)
    },
    async updateCatalog ({
      state,
      commit
    }, data) {
      return axios.put(baseURL + '/api/admin/drop-categories/' + data.id, data)
    },
    async moveCatalog ({
      state,
      commit
    }, data) {
      const promise = axios.put(baseURL + '/api/admin/drop-categories/move', data)
      promise.then(response => {
      })

      return promise
    },
    async deleteCategory ({
      state,
      commit
    }, id) {
      return axios.delete(baseURL + '/api/admin/drop-categories/' + id)
    }
  }
}
