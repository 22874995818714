<template>
  <v-navigation-drawer
    class="main-bg"
    v-model="show_aside"
    app
    dark
    clipped
    src="/bg/sakura.jpeg"
    overlay-color="red"
    @input="setShow"
  >
    <v-list
      nav
      dense
    >
      <v-subheader>Меню</v-subheader>
      <v-list-item-group
        active-class="gray--text"
      >
        <v-list-item to="/dashboard/catalog">
          <v-icon class="mr-2">list</v-icon>
          <v-list-item-title>Каталог</v-list-item-title>
        </v-list-item>

        <v-list-item to="/dashboard/products">
          <v-icon class="mr-2">category</v-icon>
          <v-list-item-title>{{ $t('products.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/clients">
          <v-icon class="mr-2">person</v-icon>
          <v-list-item-title>{{ $t('clients.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/orders/etalon/reserves">
          <v-icon class="mr-2">wysiwyg</v-icon>
          <v-list-item-title>{{ $t('orders.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-item to="/dashboard/inet-orders">
          <v-icon class="mr-2">wysiwyg</v-icon>
          <v-list-item-title>{{ $t('inet-orders.label') }}</v-list-item-title>
        </v-list-item>
        <v-list-group
          no-action
        >
          <template v-slot:activator>
            <v-icon class="mr-2">query_stats</v-icon>
            <v-list-item-title>Статистика</v-list-item-title>
          </template>
          <v-list-item to="/dashboard/statistics/products">
            <v-list-item-title>Товары</v-list-item-title>
          </v-list-item>
          <v-list-item to="/dashboard/statistics/orders">
            <v-list-item-title>Заказы</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'MainMenuAsideComponent',
  props: ['is_show'],
  data: () => ({
    show_aside: false
  }),
  watch: {
    is_show: {
      immediate: true,
      handler (v) {
        this.show_aside = v
        this.$emit('show-main-menu', v)
      }
    }
  },
  methods: {
    setShow (v) {
      this.$emit('show-main-menu', v)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
