<template>
  <v-dialog
    v-model="showModal"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Редактирование свойства категории
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Название Свойства RU"
          v-model="name_ru"
        />
        <v-text-field
          label="Название Свойства UA"
          v-model="name_uk"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="closeModal"
        >
          Отмена
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="savePropertyType"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EditCategoryPropertyModal',
  props: [
    'category_id',
    'property_type',
    'show'
  ],
  data () {
    return {
      type_id: null,
      name_ru: null,
      name_uk: null
    }
  },
  watch: {
    property_type: {
      immediate: false,
      deep: true,
      handler (v) {
        if (v) {
          this.type_id = v.id
          this.name_ru = v.name
          this.name_uk = v.name_uk
        }
      }
    }
  },
  computed: {
    ...mapState('propertyTypes', [
      'property_type_list'
    ]),
    showModal: {
      get () {
        return this.show
      },
      set (v) {
        this.$emit('show', v)
      }
    }
  },
  methods: {
    ...mapActions('propertyTypes', [
      'updatePropertyType'
    ]),
    savePropertyType () {
      const data = {
        id: this.type_id,
        name: this.name_ru,
        name_uk: this.name_uk
      }

      const promise = this.updatePropertyType(data)
      promise.then(r => {
        this.closeModal()
      })
    },
    closeModal () {
      this.showModal = false
      this.type_id = null
      this.name_ru = null
      this.name_uk = null
    }
  }
}
</script>

<style scoped>

</style>
