import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/admin/property-types'

export default {
  namespaced: true,
  state: {
    category_type_list: [],
    property_type_list: [],
    base_property_type_list: [],
    is_loading: false
  },
  mutations: {
    setCategoryTypeList (state, value) {
      state.category_type_list = value
    },
    setPropertyTypeList (state, value) {
      state.property_type_list = value
    },
    setBasePropertyTypeList (state, value) {
      state.base_property_type_list = value
    },
    addToCategoryList (state, value) {
      state.category_type_list.push(value)
    },
    updateInCategoryList (state, payload) {
      const index = state.category_type_list.findIndex(item => item.id === payload.id)

      if (index !== -1) {
        state.category_type_list[index] = payload
        state.category_type_list = [...state.category_type_list]
      }
    },
    deleteItemCategory (state, id) {
      const index = state.category_type_list.findIndex(item => item.id === id)

      if (index !== -1) {
        state.category_type_list.splice(index, 1)
        state.category_type_list = [...state.category_type_list]
      }
    }
  },
  actions: {
    getPropertyTypeList ({ commit }, data) {
      axios.get(baseURL, { params: data }).then(response => {
        commit('setPropertyTypeList', response.data.data)
      })
    },
    getBasePropertyTypeList ({ commit }) {
      axios.get(baseURL + '/get-base').then(response => {
        commit('setBasePropertyTypeList', response.data.data)
      })
    },
    getCategoryTypeList ({ state, commit }, id) {
      state.is_loading = true
      axios.get(baseURL, {
        params: {
          category_id: id
        }
      }).then(response => {
        commit('setCategoryTypeList', response.data.data)
        state.is_loading = false
      }).catch(e => {
        state.is_loading = false
      })
    },
    async storePropertyType ({ commit }, data) {
      const promise = axios.post(baseURL, data)
      promise.then(response => {
        commit('addToCategoryList', response.data.data)
      })
      return promise
    },
    async updatePropertyType ({ commit }, data) {
      const promise = axios.put(baseURL + '/' + data.id, data)
      promise.then(response => {
        commit('updateInCategoryList', response.data.data)
      })
      return promise
    },
    async storeIsFilter ({ commit }, data) {
      return axios.post(baseURL + '/filter', data)
    },
    async deletePropertyTypeForCategory ({ commit }, data) {
      const promise = axios.delete(baseURL + '/' + data.id + '/category', {
        params: data
      })
      promise.then(response => {
        commit('deleteItemCategory', data.id)
      })
      return promise
    },
    async attachPropertyType ({ commit }, data) {
      const promise = axios.put(baseURL + '/' + data.id + '/attach', data)
      promise.then(response => {
        commit('addToCategoryList', response.data.data)
      })
      return promise
    }
  }
}
