<template>
  <v-dialog
    v-model="showModal"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Удаление свойства из категории
      </v-card-title>
      <v-card-text>
        Вы действительно хотите удалить это свойство <b>{{property_type ? property_type.name : '-'}}</b> из категории?<br>
        <b class="red--text darken-1">Внимание! это свойство будет удалено у всех тваров этой категории</b>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="closeModal"
        >
          Отмена
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="deletePropertyType"
        >
          Удалить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DeleteCategoryPropertyModal',
  props: [
    'category_id',
    'property_type',
    'show'
  ],
  computed: {
    showModal: {
      get () {
        return this.show
      },
      set (v) {
        this.$emit('show', v)
      }
    }
  },
  methods: {
    ...mapActions('propertyTypes', [
      'deletePropertyTypeForCategory'
    ]),
    deletePropertyType () {
      const data = {
        id: this.property_type.id,
        category_id: this.category_id
      }
      const promise = this.deletePropertyTypeForCategory(data)
      promise.then(r => {
        this.closeModal()
      })
    },
    closeModal () {
      this.showModal = false
    }
  }
}
</script>

<style scoped>

</style>
