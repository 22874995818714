import axios from 'axios'
import { eventBus } from '@/main'

const baseURL = process.env.VUE_APP_USER_SERVER

export default {
  namespaced: true,
  state: {
    is_sending: false
  },
  mutations: {},
  actions: {
    async sendNotification ({
      state
    }, data) {
      state.is_sending = true
      const promise = axios.post(baseURL + '/api/admin/notifications', data)
      promise.then(response => {
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Отправлено',
          'Сообщение успешно отправлено!'
        )
        state.is_sending = false
      }).catch(e => {
        state.is_sending = false
      })

      return promise
    }
  }
}
