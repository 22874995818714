import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE

export default {
  namespaced: true,
  state: {
    is_loading: false,
    categories: [],
    category: null,
    filters: {
      name: null
    },
    show_create_category: false,
    show_edit_category: false,
    show_move_category: false
  },
  mutations: {
    setCategories (state, payload) {
      state.categories = payload
    },
    setCategory (state, payload) {
      state.category = payload
    },
    setSearch (state, payload) {
      state.filters.search = payload
    },
    setShowCreateCategory (state, v) {
      state.show_create_category = v
    },
    setShowEditCategory (state, v) {
      state.show_edit_category = v
    },
    setShowMoveCategory (state, v) {
      state.show_move_category = v
    }
  },
  actions: {
    async getCylinderCatalog ({
      state,
      commit
    }, parentId) {
      state.is_loading = true
      const promise = axios.get(baseURL + '/api/admin/cylinder-categories/list', {
        params: {
          parent_id: parentId
        }
      })
      promise.then(response => {
        commit('setCategories', response.data.data)
        state.is_loading = false
      })

      return promise
    }
  }
}
