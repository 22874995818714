import axios from 'axios'

const baseURL = process.env.VUE_APP_ORDER_SERVICE

export default {
  namespaced: true,
  state: {
    product_statistic_load: false,
    order_day_statistic: [],
    order_week_statistic: [],
    product_statistic: [],
    total: 0,
    count: 0,
    period: {
      from: null,
      to: null
    }
  },
  mutations: {
    setProductStatisticLoad (state, value) {
      state.product_statistic_load = value === true
    },
    setTotal (state, v) {
      state.total = v
    },
    setCount (state, v) {
      state.count = v
    },
    setProductStatistic (state, payload) {
      state.product_statistic = payload
    },
    setOrderDayStatistic (state, payload) {
      state.order_day_statistic = payload
    },
    setOrderWeekStatistic (state, payload) {
      state.order_week_statistic = payload
    },
    setPeriod (state, payload) {
      state.period = payload
    },
    clearProductStatistics (state) {
      state.product_statistic = null
      state.count = 0
      state.total = 0
    }
  },
  actions: {
    async getProductStatistic ({
      state,
      commit
    }) {
      commit('setProductStatisticLoad', true)
      const promise = axios.get(baseURL + '/api/statistics/products', {
        params: {
          from: state.period.from,
          to: state.period.to
        }
      })
      promise.then(response => {
        commit('setProductStatistic', response.data.products)
        commit('setTotal', response.data.total)
        commit('setCount', response.data.count)
        commit('setProductStatisticLoad', false)
      }).catch(e => {
        commit('setProductStatisticLoad', false)
      })
      return promise
    },
    async getOrderStatistic ({
      state,
      commit
    }) {
      commit('setProductStatisticLoad', true)
      const promise = axios.get(baseURL + '/api/statistics/sell', {
        params: {
          from: state.period.from,
          to: state.period.to
        }
      })
      promise.then(response => {
        commit('setOrderDayStatistic', response.data.days)
        commit('setOrderWeekStatistic', response.data.weeks)
        commit('setTotal', response.data.total)
        commit('setProductStatisticLoad', false)
      }).catch(e => {
        commit('setProductStatisticLoad', false)
      })
      return promise
    }
  }
}
