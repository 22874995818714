<template>
  <v-navigation-drawer
    v-model="show_aside"
    app
    right
    temporary
    clipped
    width="1000px"
  >
    <v-card :loading="is_loading" flat>
      <v-toolbar
        color="rgba(0, 0, 0, 0)"
        theme="dark"
        flat
      >
        <v-btn icon @click="show_aside = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <div>Свойства</div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          @click="show_create_property_type = true"
          class="ms-4"
          color="blue"
          dark
          small
        >
          Добавить свойство категории
        </v-btn>
      </v-toolbar>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col sm="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left"></th>
                  <th class="text-left">
                    Тип
                  </th>
                  <th class="text-left">
                    Значение
                  </th>
                  <th class="text-right"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(item, index) in typeListComputed"
                  :key="item.id"
                >
                  <td>
                    <v-checkbox
                      v-model="item.is_filter"
                      @change="updateIsFilter(item)"
                    />
                  </td>
                  <td>
                    <div class="mt-4 mb-4"><b>{{ item.name }}</b>
                      <v-btn
                        @click="editProperty(item)"
                        class="ms-2"
                        color="orange"
                        x-small icon
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </div>
                    <v-divider class="mx-6"></v-divider>
                    <div class="mt-4 mb-4"><b>{{ item.name_uk }}</b></div>
                  </td>
                  <td style="padding: 15px">
                    <div v-if="selected_ids && selected_ids.length">
                    <v-combobox
                      v-model="item.value.value"
                      @keyup="searchValues(item.id, item.value.value)"
                      :items="values"
                      class="mb-1"
                      label="Значение RU"
                      item-text="value"
                      solo
                      dense
                      hide-details
                      @change="selectValueRU($event, index)"
                    />
                    <v-combobox
                      v-model="item.value.value_uk"
                      @keyup="searchValuesUK(item.id, item.value.value_uk)"
                      :items="values"
                      label="Значение UA"
                      item-text="value_uk"
                      solo
                      dense
                      hide-details
                      @change="selectValueUK($event, index)"
                    />
                    </div>
                    <div v-else>Количество товаров: {{item.products}}</div>
                  </td>
                  <td class="text-right">
                    <v-btn
                      @click="showDeletePropertyType(item)"
                      class="ms-2"
                      color="red"
                      x-small
                      icon>
                      <v-icon>close</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              v-if="selected_ids && selected_ids.length"
              @click="saveProductProperties"
              :disabled="is_processed"
              :loading="is_processed"
              color="primary"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <CreateCategoryPropertyModal
      :category_id="this.$route.params.id"
      :show="show_create_property_type"
      @show="e => show_create_property_type = e"
    />
    <EditCategoryPropertyModal
      :show="show_edit_property_type"
      :property_type="edited_property"
      @show="e => show_edit_property_type = e"
    />
    <DeleteCategoryPropertyModal
      :category_id="this.$route.params.id"
      :show="show_delete_property_type"
      :property_type="edited_property"
      @show="e => show_delete_property_type = e"
    />
  </v-navigation-drawer>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CreateCategoryPropertyModal from '@/components/Catalog/Modals/CreateCategoryPropertyModal'
import EditCategoryPropertyModal from '@/components/Catalog/Modals/EditCategoryPropertyModal'
import DeleteCategoryPropertyModal from '@/components/Catalog/Modals/DeleteCategoryPropertyModal'

export default {
  name: 'CategoryPropertiesAsideComponent',
  components: { DeleteCategoryPropertyModal, EditCategoryPropertyModal, CreateCategoryPropertyModal },
  data () {
    return {
      show_create_property_type: false,
      show_edit_property_type: false,
      show_delete_property_type: false,
      edited_property: null,
      timer: null,
      values: []
    }
  },
  computed: {
    ...mapGetters('products', ['selected_ids']),
    ...mapState('properties', ['show_properties_panel', 'is_processed']),
    ...mapState('propertyTypes', ['category_type_list', 'is_loading']),
    show_aside: {
      get () {
        return this.show_properties_panel
      },
      set (v) {
        this.setShowPropertiesPanel(v)
        if (v) {
          this.getCategoryTypeList(this.$route.params.id)
        } else {
          this.setCategoryTypeList([])
        }
      }
    },
    typeListComputed: {
      get () {
        return this.category_type_list.map(item => ({
          ...item,
          value: item.value
            ? item.value
            : {
                value: null,
                value_uk: null
              }
        }))
      },
      set (v) {
        this.setCategoryTypeList(v)
      }
    }
  },
  methods: {
    ...mapActions('propertyTypes', [
      'getCategoryTypeList',
      'storeIsFilter'
    ]),
    ...mapActions('properties', ['storeProperty']),
    ...mapActions('propertyValues', ['getPropertyValueList']),
    ...mapMutations('properties', ['setShowPropertiesPanel']),
    ...mapMutations('propertyTypes', ['setCategoryTypeList']),
    searchValues (typeId, search) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        const filters = {
          type_id: typeId,
          value: search
        }
        const promise = this.getPropertyValueList(filters)
        promise.then(r => {
          this.values = r.data.data
        })
      }, 100)
    },
    searchValuesUK (typeId, search) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        const filters = {
          type_id: typeId,
          value_uk: search
        }
        const promise = this.getPropertyValueList(filters)
        promise.then(r => {
          this.values = r.data.data
        })
      }, 100)
    },
    selectValueRU (v, index) {
      const currentItem = this.typeListComputed[index]
      if (v && typeof v === 'object') {
        currentItem.value.value_id = v.id
        currentItem.value.value = v.value
        currentItem.value.value_uk = v.value_uk
      } else {
        currentItem.value.value_id = null
        currentItem.value.value = v
        if (!currentItem.value.value_uk) {
          currentItem.value.value_uk = v
        }
      }
      this.typeListComputed[index] = currentItem
      this.typeListComputed = [...this.typeListComputed]
      this.values = []
    },

    selectValueUK (v, index) {
      const currentItem = this.typeListComputed[index]
      if (v && typeof v === 'object') {
        currentItem.value.value_id = v.id
        currentItem.value.value = v.value
        currentItem.value.value_uk = v.value_uk
      } else {
        currentItem.value.value_id = null
        currentItem.value.value_uk = v
        if (!currentItem.value.value) {
          currentItem.value.value = v
        }
      }
      this.typeListComputed[index] = currentItem
      this.typeListComputed = [...this.typeListComputed]
      this.values = []
    },
    saveProductProperties () {
      const productIds = this.selected_ids
      const categoryId = this.$route.params.id
      const typeData = []

      this.typeListComputed.forEach(item => {
        if (item.value.value_uk || item.value.value) {
          typeData.push({
            type_id: item.id,
            value: {
              value_id: item.value.value_id,
              value: item.value.value,
              value_uk: item.value.value_uk
            }
          })
        }
      })

      const data = {
        category_id: categoryId,
        products: productIds,
        types: typeData
      }
      this.storeProperty(data)
    },
    editProperty (item) {
      this.edited_property = item
      this.show_edit_property_type = true
    },
    showDeletePropertyType (item) {
      this.edited_property = item
      this.show_delete_property_type = true
    },
    updateIsFilter (item) {
      const categoryId = this.$route.params.id
      const data = {
        category_id: categoryId,
        type_id: item.id,
        is_filter: item.is_filter ? 1 : 0
      }
      this.storeIsFilter(data)
    }
  },
  created () {
  }
}
</script>

<style scoped>

</style>
