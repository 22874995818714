
export const changesLog = {
  data () {
    return {
      versionLog: '0.0.7',
      changes: [
        {
          title: 'Заказы (ETALON)',
          lines: [
            'Добавлено отслеживание заказов отправленных Новой Почтой',
            'Трэкинг заказов с 7:00 до 21:00, обновление каждые 4 часа ',
            'В фильтр заказов добавлен "Статус доставки"'
          ]
        }
      ]
    }
  },
  methods: {
    getLogHTML () {
      let html = ''
      this.changes.forEach(item => {
        html += '<h3 class="mt-2">' + item.title + '</h3>'
        html += '<ul>'
        item.lines.forEach(line => {
          html += '<li>' + line + '</li>'
        })
        html += '</ul>'
      })

      return html
    }
  }
}
