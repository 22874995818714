<template>
  <div v-if="user">
    <v-app-bar
      app
      color="grey.darken4"
      clipped-left
      clipped-right
      dark
      elevation="8"
    >
      <v-app-bar-nav-icon @click="show_main_menu = !show_main_menu"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Etalon Logo"
          class="shrink mr-2 ml-2"
          contain
          src="/icon192.png"
          transition="scale-transition"
          width="30"
        />
      </div>
      <v-toolbar-title>ETALON ADMIN</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        @click="show_aside = !show_aside"
      >
        <v-icon dark>
          settings
        </v-icon>
      </v-btn>
    </v-app-bar>
    <MainMenuAsideComponent :is_show="show_main_menu" @show-main-menu="setShowMenu"/>
    <SettingsMenuAsideComponent :is_show="show_aside"/>
    <v-main>
      <transition name="fade-transition" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
  </div>
  <div v-else class="text-center" style="margin-top: 50vh;">
    <v-progress-circular
      :size="50"
      color="amber"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import MainMenuAsideComponent from '@/components/asides/MainMenuAsideComponent'
import SettingsMenuAsideComponent from '@/components/asides/SettingsMenuAsideComponent'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'DashboardMobileLayout',
  components: {
    SettingsMenuAsideComponent,
    MainMenuAsideComponent
  },
  data: () => ({
    show_main_menu: false,
    show_aside: false
  }),
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['logout']),
    setShowMenu (v) {
      this.show_main_menu = v
    }
  }
}
</script>

<style scoped>

</style>
