import { mapMutations, mapState } from 'vuex'
import { eventBus } from '@/main'

export const ws = {
  data () {
    return {
      showTelegramModel: false
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapMutations('inetOrders', ['setOrder']),
    joinChannelInetOrder () {
      window.Echo.channel('user.' + this.user.id)
        .listen('.orders.updated', (e) => {
          if (this.$route.path === '/dashboard/inet-orders/' + e.id) {
            this.setOrder(e)
          }
        }).listen('.orders.message', (e) => {
          let type = 'success'
          switch (e.type) {
            case 1:
              type = 'success'
              break
            case 2:
              type = 'warn'
              break
            default:
              type = 'error'
          }
          eventBus.$root.$emit(
            'send_notify',
            type,
            'Сообщение 1С',
            e.message
          )
        })
    }
  }
}
