import axios from 'axios'
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    lang: localStorage.getItem('etalon-admin-locale') || 'ua',
    lang_list: ['ua', 'ru']
  },
  mutations: {
    setLang (state, value) {
      localStorage.setItem('etalon-admin-locale', value)
      state.lang = value
      i18n.locale = value
      axios.defaults.headers.localization = value
      // window.location = router.currentRoute.path
    }
  },
  actions: {}
}
