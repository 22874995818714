import axios from 'axios'

const baseURL = process.env.VUE_APP_INET_ORDER_SERVICE + '/api/orders'
const historyURL = process.env.VUE_APP_INET_ORDER_SERVICE + '/api/order-histories'

export default {
  namespaced: true,
  state: {
    is_load_orders: false,
    list_per_page: [10, 20, 30, 50, 100],
    orders: [],
    order: null,
    rz_order: null,
    order_pagination: {
      current_page: 1,
      per_page: 10,
      total: 0
    },
    order_filters: {
      search: null,
      status: null,
      pay_method_id: null,
      type: null,
      order_by: 'id',
      order_direction: 'desc',
      sorting: localStorage.getItem('etalon-admin-inet-orders-sort') || 2
    },
    logs: null
  },
  mutations: {
    setIsLoadOrders (state, value) {
      state.is_load_orders = value === true
    },
    setOrders (state, payload) {
      state.orders = payload
    },
    setOrderPagination (state, payload) {
      state.order_pagination = payload
    },
    setOrderPage (state, payload) {
      state.order_pagination.current_page = payload
    },
    setOrderPerPage (state, payload) {
      state.order_pagination.per_page = payload
      localStorage.setItem('etalon-admin-orders-per-page', payload)
    },
    setOrder (state, payload) {
      state.order = payload
    },
    setRzOrder (state, payload) {
      state.rz_order = payload
    },
    setOrderSearch (state, payload) {
      state.order_filters.search = payload
    },
    setSorting (state, v) {
      state.order_filters.sorting = v
      localStorage.setItem('etalon-admin-inet-orders-sort', v)
    },
    setStatus (state, v) {
      state.order_filters.status = v
    },
    setFilterPayMethod (state, v) {
      state.order_filters.pay_method_id = v
    },
    setFilterType (state, v) {
      state.order_filters.type = v
    },
    setLogs (state, payload) {
      state.logs = payload
    }
  },
  actions: {
    async getOrders ({
      state,
      commit
    }) {
      commit('setIsLoadOrders', true)
      switch (parseInt(state.order_filters.sorting)) {
        case 1:
          state.order_filters.order_by = 'created_at'
          state.order_filters.order_direction = 'asc'
          break
        case 2:
          state.order_filters.order_by = 'created_at'
          state.order_filters.order_direction = 'desc'
          break
      }
      const promise = axios.get(baseURL, {
        params: {
          status_id: state.order_filters.status,
          type: state.order_filters.type,
          pay_method_id: state.order_filters.pay_method_id,
          page: state.order_pagination.current_page,
          per_page: state.order_pagination.per_page,
          search: state.order_filters.search,
          order_by: state.order_filters.order_by,
          order_direction: state.order_filters.order_direction
        }
      })
      promise.then(response => {
        commit('setOrders', response.data.data)
        commit('setOrderPagination', response.data.meta)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    },
    async getOrder ({
      state,
      commit
    }, id) {
      commit('setIsLoadOrders', true)
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setOrder', response.data.data)
        commit('setRzOrder', response.data.rz_order)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    },
    async updateOrder ({ state, commit }, data) {
      commit('setIsLoadOrders', true)
      const promise = axios.put(baseURL + '/' + data.id, data)
      promise.then(response => {
        commit('setOrder', response.data.data)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    },
    async createEN ({ state, commit }, data) {
      commit('setIsLoadOrders', true)
      const promise = axios.post(baseURL + '/create-en', data)
      promise.then(response => {
        commit('setOrder', response.data.data)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    },
    async confirmOrder ({ state, commit }, data) {
      return axios.post(baseURL + '/' + data.id)
    },
    async updateProductsRozetka ({ state, commit }, id) {
      return axios.post(baseURL + '/' + id + '/rz-products')
    },
    async cancelOrder ({ state, commit }, data) {
      return axios.delete(baseURL + '/' + data.id, {
        data: data
      })
    },
    async getHistory ({ state, commit }, id) {
      return axios.get(historyURL + '/' + id)
    }
  }
}
