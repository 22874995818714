<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card elevation="24" width="360">
        <v-toolbar
          flat
        >
          <v-toolbar-title>Вход</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              autofocus
              v-model="login"
              label="login"
              prepend-icon="person"
              type="text"
              autocomplete="false"
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="password"
              prepend-icon="password"
              type="password"
              autocomplete="false"
            ></v-text-field>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="sendLogin()"
            >
              Login
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
  name: 'LoginView',
  data: () => ({
    login: null,
    password: null
  }),
  watch: {
    user: {
      handler (v) {
        if (v.id) {
          this.$router.push('dashboard/catalog')
        }
      }
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['loginRequest']),
    sendLogin () {
      const promise = this.loginRequest({
        login: this.login,
        password: this.password
      })

      promise.then(r => {
        this.$router.push('dashboard/catalog')
      })
    }
  }
}
</script>
