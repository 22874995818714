import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import RequestErrors from './plugins/errorHelper'
import Notifications from 'vue-notification'
import VueMoment from 'vue-moment'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import BaseLayout from '@/layouts/BaseLayout'
import BaseMobileLayout from '@/layouts/mobile/BaseMobileLayout'
import DashboardLayout from '@/layouts/DashboardLayout'
import DashboardMobileLayout from '@/layouts/mobile/DashboardMobileLayout'

import Echo from 'laravel-echo'

i18n.locale = localStorage.getItem('etalon-admin-locale') || 'ua'
axios.defaults.headers.crossdomain = true
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token') || ''}`

Vue.component('base-layout', BaseLayout)
Vue.component('base-layout-mobile', BaseMobileLayout)
Vue.component('dashboard-layout', DashboardLayout)
Vue.component('dashboard-layout-mobile', DashboardMobileLayout)

Vue.config.productionTip = true
RequestErrors.connect()
Vue.use(Notifications)
Vue.use(VueMoment)

export const eventBus = new Vue()

window.io = require('socket.io-client')
window.Echo = new Echo({
  broadcaster: 'socket.io',
  host: process.env.VUE_APP_SOCKET_HOST,
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token') || ''}`
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
