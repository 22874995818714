import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/admin/analogues'
const productURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/admin/products'

export default {
  namespaced: true,
  state: {
    image_url: process.env.VUE_APP_IMAGE_SERVER,
    is_load_analogues: false,
    analogues: [],
    analogue_products: []
  },
  mutations: {
    setLoadAnalogues (state, value) {
      state.is_load_analogues = value === true
    },
    setAnalogues (state, payload) {
      state.analogues = payload
    },
    setAnalogueProducts (state, payload) {
      state.analogue_products = payload
    }
  },
  actions: {
    async searchAnalogs ({
      state,
      commit
    }, data) {
      commit('setLoadAnalogues', true)
      const promise = axios.get(productURL, {
        params: {
          search: data.search,
          exclude: data.id,
          per_page: 50
        }
      })
      promise.then(response => {
        commit('setAnalogueProducts', response.data.data)
        commit('setLoadAnalogues', false)
      }).catch(e => {
        commit('setLoadAnalogues', false)
      })
      return promise
    },
    async getAnalogues ({
      state,
      commit
    }, id) {
      commit('setLoadAnalogues', true)
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setAnalogues', response.data.data)
        commit('setLoadAnalogues', false)
      }).catch(e => {
        commit('setLoadAnalogues', false)
      })
      return promise
    },
    async storeAnalogues ({
      state,
      commit
    }, data) {
      const ids = []
      data.analogues.forEach(item => {
        ids.push(item.id)
      })
      commit('setLoadAnalogues', true)
      const promise = axios.post(baseURL, {
        product_id: data.id,
        analogues: ids
      })
      promise.then(response => {
        commit('setAnalogues', response.data.data)
        commit('setLoadAnalogues', false)
      }).catch(e => {
        commit('setLoadAnalogues', false)
      })
      return promise
    },
    async deleteAnalogues ({
      state,
      commit
    }, data) {
      const ids = []
      data.analogues.forEach(item => {
        ids.push(item.id)
      })

      commit('setLoadAnalogues', true)
      const promise = axios.delete(baseURL, {
        params: {
          product_id: data.id,
          analogues: ids
        }
      })
      promise.then(response => {
        commit('setAnalogues', response.data.data)
        commit('setLoadAnalogues', false)
      }).catch(e => {
        commit('setLoadAnalogues', false)
      })
      return promise
    }
  }
}
