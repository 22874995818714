<template>
  <v-dialog
    v-model="showModal"
    max-width="500px"
    persistent
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Создание свойства категории
      </v-card-title>
      <v-card-text>
        <v-combobox
          label="Название Свойства RU"
          :items="property_type_list"
          item-text="name"
          :search-input.sync="searchTypeRu"
          @change="selectType"
        />
        <v-combobox
          label="Название Свойства UA"
          :items="property_type_list"
          :search-input.sync="searchTypeUk"
          item-text="name_uk"
          @change="selectType"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="closeModal"
        >
          Отмена
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="savePropertyType"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CreateCategoryPropertyModal',
  props: [
    'category_id',
    'show'
  ],
  data () {
    return {
      timer: null,
      type_id: null,
      name_ru: null,
      name_uk: null,
      search: {
        name: null,
        name_uk: null
      }
    }
  },
  computed: {
    ...mapState('propertyTypes', [
      'property_type_list'
    ]),
    showModal: {
      get () {
        return this.show
      },
      set (v) {
        this.$emit('show', v)
      }
    },
    searchTypeRu: {
      get () {
        return this.name_ru
      },
      set (v) {
        this.name_ru = v
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.search.name = this.name_ru
          this.search.name_uk = null
          this.getPropertyTypeList(this.search)
        }, 300)
      }
    },
    searchTypeUk: {
      get () {
        return this.name_uk
      },
      set (v) {
        this.name_uk = v
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.search.name = null
          this.search.name_uk = this.name_uk
          this.getPropertyTypeList(this.search)
        }, 300)
      }
    }
  },
  methods: {
    ...mapActions('propertyTypes', [
      'getPropertyTypeList',
      'storePropertyType',
      'attachPropertyType'
    ]),
    selectType (v) {
      if (v && typeof v === 'object') {
        this.type_id = v.id
        this.name_ru = v.name
        this.name_uk = v.name_uk
      } else {
        this.type_id = null
      }
    },
    savePropertyType () {
      if (this.type_id === null) {
        const data = {
          name: this.name_ru,
          name_uk: this.name_uk,
          category_id: this.category_id
        }

        const promise = this.storePropertyType(data)
        promise.then(r => {
          this.closeModal()
        })
      } else {
        const data = {
          id: this.type_id,
          category_id: this.category_id
        }
        const promise = this.attachPropertyType(data)
        promise.then(r => {
          this.closeModal()
        })
      }
    },
    closeModal () {
      this.showModal = false
      this.type_id = null
      this.name_ru = null
      this.name_uk = null
    }
  }
}
</script>

<style scoped>

</style>
