import axios from 'axios'

const baseURL = process.env.VUE_APP_CONTENT_SERVICE + '/api/articles'

export default {
  namespaced: true,
  state: {
    articles: [],
    article: {
      name: null,
      content: null,
      icon: null
    }
  },
  mutations: {
    setAsideArticles (state, v) {
      state.articles = v
    },
    setArticle (state, v) {
      state.article = v
    }
  },
  actions: {
    async getArticles ({
      state,
      commit
    }) {
      const promise = axios.get(baseURL, {})

      promise.then(response => {
        commit('setAsideArticles', response.data.data)
      }).catch(e => {
      })
      return promise
    },
    async getArticle ({
      state,
      commit
    }, id) {
      const promise = axios.get(baseURL + '/' + id, {})

      promise.then(response => {
        commit('setArticle', response.data.data)
      }).catch(e => {
      })
      return promise
    },
    async createArticle ({
      state,
      commit
    }) {
      state.article.position = 1
      state.article.sort = 1
      const promise = axios.post(baseURL, state.article)

      promise.then(response => {
        commit('setArticle', response.data.data)
      }).catch(e => {
      })
      return promise
    },
    async updateArticle ({
      state,
      commit
    }) {
      const promise = axios.put(baseURL + '/' + state.article.id, state.article)

      promise.then(response => {
        commit('setArticle', response.data.data)
      }).catch(e => {
      })
      return promise
    },
    async deleteArticle ({
      state,
      commit
    }, id) {
      const promise = axios.delete(baseURL + '/' + id)

      promise.then(response => {}).catch(e => {})
      return promise
    }
  }
}
