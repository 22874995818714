import axios from 'axios'

const getUrl = function (storeName) {
  switch (storeName) {
    case 'cylinder':
      return process.env.VUE_APP_INET_ORDER_SERVICE + '/api/nova-senders'
    case 'etalon':
      return process.env.VUE_APP_ORDER_SERVICE + '/api/nova-senders'
  }
  return ''
}

export default {
  namespaced: true,
  state: {
    is_load: false,
    senders: [],
    sender: null
  },
  mutations: {
    setSenders (state, payload) {
      state.senders = payload
    },
    setSender (state, payload) {
      state.sender = payload
    }
  },
  actions: {
    async getSenders ({ state, commit }, storeName) {
      state.is_load = true
      commit('setSenders', [])
      const url = getUrl(storeName)
      const promise = axios.get(url, {
        params: {
          per_page: 30
        }
      })
      promise.then(response => {
        commit('setSenders', response.data.data)
        state.is_load = false
      })
      return promise
    },
    async createSender ({
      state,
      commit
    }, data) {
      const url = getUrl(data.storeName)
      const promise = axios.post(url, data)
      promise.then(response => {
        commit('setSender', response.data.data)
      })
      return promise
    },
    async updateSender ({
      state,
      commit
    }, data) {
      const url = getUrl(data.storeName)
      const promise = axios.put(url + '/' + data.id, data)
      promise.then(response => {
        commit('setSender', response.data.data)
      })
      return promise
    },
    async deleteSender ({
      state,
      commit
    }, data) {
      const url = getUrl(data.storeName)
      axios.delete(url + '/' + data.id)
    }
  }
}
