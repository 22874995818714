import axios from 'axios'

const baseURL = process.env.VUE_APP_INET_ORDER_SERVICE + '/api/rozetka-users'

export default {
  namespaced: true,
  state: {
    is_load: false,
    rozetka_stores: [],
    rozetka_store: null
  },
  mutations: {
    setRozetkaStores (state, payload) {
      state.rozetka_stores = payload
    },
    setRozetkaStore (state, payload) {
      state.rozetka_store = payload
    }
  },
  actions: {
    async getRozetkaStores ({ commit }) {
      const promise = axios.get(baseURL, {
        params: {
          per_page: 30
        }
      })
      promise.then(response => {
        commit('setRozetkaStores', response.data.data)
      })
      return promise
    },
    async getRozetkaStore ({
      state,
      commit
    }, id) {
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setRozetkaStore', response.data.data)
      })
      return promise
    },
    async createRozetkaStore ({
      state,
      commit
    }, data) {
      const promise = axios.post(baseURL, data)
      promise.then(response => {
        commit('setRozetkaStore', response.data.data)
      })
      return promise
    },
    async updateRozetkaStore ({
      state,
      commit
    }, data) {
      const promise = axios.put(baseURL + '/' + data.id, data)
      promise.then(response => {
        commit('setRozetkaStore', response.data.data)
      })
      return promise
    },
    async deleteRozetkaStore ({
      state,
      commit
    }, id) {
      axios.delete(baseURL + '/' + id)
    }
  }
}
