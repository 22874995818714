import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/search'

export default {
  namespaced: true,
  state: {
    is_load: null
  },
  mutations: {},
  actions: {
    async searchProducts ({ commit }, search) {
      return axios.get(baseURL + '/list', {
        params: {
          search: search
        }
      })
    }
  }
}
