import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/systems'

export default {
  namespaced: true,
  state: {
    system_logs: null
  },
  mutations: {
    setSystemLogs (state, value) {
      state.system_logs = value
    }
  },
  actions: {
    async getLogs ({
      commit
    }) {
      const promise = axios.get(baseURL)
      promise.then(response => {
        commit('setSystemLogs', response.data.data)
      })

      return promise
    }
  }
}
