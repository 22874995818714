import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/admin/system-events'

export default {
  namespaced: true,
  state: {
    systemEvents: null,
    paginate: {
      current_page: 1,
      per_page: 15
    }
  },
  mutations: {
    setSystemLogs (state, value) {
      state.systemEvents = value
    },
    setPaginate (state, payload) {
      state.paginate = payload
    },
    setPage (state, v) {
      state.paginate.current_page = v
    }
  },
  actions: {
    async getSystemEvents ({
      state, commit
    }, filters) {
      const promise = axios.get(baseURL, {
        params: {
          page: state.paginate.current_page,
          per_page: state.paginate.per_page,
          type: filters.type
        }
      })
      promise.then(response => {
        commit('setSystemLogs', response.data.data)
        commit('setPaginate', response.data.meta)
      })

      return promise
    }
  }
}
