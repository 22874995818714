import axios from 'axios'

const baseURL = process.env.VUE_APP_USER_SERVER

export default {
  namespaced: true,
  state: {
    user: null,
    api_token: `Bearer ${localStorage.getItem('token') || ''}`
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload
    },
    setApiToken (state, token) {
      state.api_token = token
      axios.defaults.headers.common.Authorization = 'Bearer ' + token
      localStorage.setItem('token', token)
    }
  },
  actions: {
    async loginRequest ({ commit }, data) {
      const promise = axios.post(baseURL + '/api/login-admin', data)

      promise.then(response => {
        commit('setApiToken', response.data.access_token)
      })

      return promise
    },
    async getUser ({ commit }, data) {
      const promise = axios.get(baseURL + '/api/auth', data)

      promise.then(response => {
        commit('setUser', response.data.data)
      }).catch(error => {
        if (error.response.status === 401 && window.location.pathname !== '/') {
          localStorage.setItem('token', null)
          window.location = '/'
        }
      })

      return promise
    },
    logout ({ commit }) {
      commit('setUser', null)
      localStorage.setItem('token', null)
      window.location = '/'
    }
  }
}
