import axios from 'axios'

const baseURL = process.env.VUE_APP_ORDER_SERVICE + '/api/orders'
const baseLogURL = process.env.VUE_APP_ORDER_SERVICE + '/api/loggers'

export default {
  namespaced: true,
  state: {
    is_load_orders: false,
    list_per_page: [10, 20, 30, 50, 100],
    orders: [],
    order: null,
    order_pagination: {
      current_page: 1,
      per_page: 10,
      total: 0
    },
    order_filters: {
      search: null,
      delivery_method_id: null,
      delivery_status_id: null,
      pay_method_id: null,
      only_with_ttn: null,
      only_with_ttn_not_in_1c: null,
      only_created_by_1c: null,
      is_online: false,
      order_by: 'id',
      order_direction: 'desc',
      section: null
    },
    logs: null
  },
  mutations: {
    setIsLoadOrders (state, value) {
      state.is_load_orders = value === true
    },
    setOrders (state, payload) {
      state.orders = payload
    },
    setOrderPagination (state, payload) {
      state.order_pagination = payload
    },
    setOrderPage (state, payload) {
      state.order_pagination.current_page = payload
    },
    setOrderPerPage (state, payload) {
      state.order_pagination.per_page = payload
      localStorage.setItem('etalon-admin-orders-per-page', payload)
    },
    setOrder (state, payload) {
      state.order = payload
    },
    setOrderSearch (state, payload) {
      state.order_filters.search = payload
    },
    setDeliveryMethodId (state, payload) {
      state.order_filters.delivery_method_id = payload
    },
    setPayMethodId (state, payload) {
      state.order_filters.pay_method_id = payload
    },
    setOnlyWithTtn (state, payload) {
      state.order_filters.only_with_ttn = payload
    },
    setOnlyWithTtnNotIn1c (state, payload) {
      state.order_filters.only_with_ttn_not_in_1c = payload
    },
    setOnlyCreatedBy1c (state, payload) {
      state.order_filters.only_created_by_1c = payload
    },
    setOnlyIsOnline (state, payload) {
      state.order_filters.is_online = payload
    },
    setDeliveryStatusId (state, payload) {
      state.order_filters.delivery_status_id = payload
    },
    setSorting (state, data) {
      if (!data.order_by) {
        state.order_filters.order_by = 'created_at'
        state.order_filters.order_direction = 'desc'
        return
      }
      state.order_filters.order_by = data.order_by || 'created_at'
      state.order_filters.order_direction = data.order_direction === 'desc' ? 'desc' : 'asc'
    },
    setSection (state, value) {
      state.order_filters.section = value
    },
    setLogs (state, payload) {
      state.logs = payload
    }
  },
  actions: {
    async getOrders ({
      state,
      commit
    }) {
      commit('setIsLoadOrders', true)
      const promise = axios.get(baseURL, {
        params: {
          page: state.order_pagination.current_page,
          per_page: state.order_pagination.per_page,
          order_by: state.order_filters.order_by,
          order_direction: state.order_filters.order_direction,
          section: state.order_filters.section,
          search: state.order_filters.search,
          delivery_method_id: state.order_filters.delivery_method_id,
          pay_method_id: state.order_filters.pay_method_id,
          only_with_ttn: state.order_filters.only_with_ttn ? 1 : 0,
          only_with_ttn_not_in_one_c: state.order_filters.only_with_ttn_not_in_1c ? 1 : 0,
          only_created_by_one_c: state.order_filters.only_created_by_1c ? 1 : 0,
          is_online: state.order_filters.is_online ? 1 : 0,
          delivery_status_id: state.order_filters.delivery_status_id
        }
      })
      promise.then(response => {
        commit('setOrders', response.data.data)
        commit('setOrderPagination', response.data.pagination)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    },
    async getClientOrders ({
      state,
      commit
    }, filters) {
      return axios.get(baseURL, {
        params: filters
      })
    },
    async getOrder ({
      state,
      commit
    }, id) {
      commit('setIsLoadOrders', true)
      commit('setOrder', null)
      const promise = axios.get(baseURL + '/' + id)
      promise.then(response => {
        commit('setOrder', response.data.data)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    },
    async updateOrder ({
      state,
      commit
    }, data) {
      commit('setIsLoadOrders', true)
      const promise = axios.put(baseURL + '/' + data.id, data)
      promise.then(response => {
        commit('setOrder', response.data.data)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    },
    async createEN ({ state, commit }, data) {
      commit('setIsLoadOrders', true)
      const promise = axios.post(baseURL + '/create-en', data)
      promise.then(response => {
        commit('setOrder', response.data.data)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    },
    async sendTtnTo1C ({ state, commit }, data) {
      return axios.post(baseURL + '/send-ttn-to-1c/' + data.id)
    },
    async getLogs ({
      state,
      commit
    }, id) {
      commit('setIsLoadOrders', true)
      const promise = axios.get(baseLogURL, {
        params: {
          order_id: id,
          per_page: 200
        }
      })
      promise.then(response => {
        commit('setLogs', response.data.data)
        commit('setIsLoadOrders', false)
      }).catch(e => {
        commit('setIsLoadOrders', false)
      })
      return promise
    }
  }
}
