import axios from 'axios'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/admin/property-values'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async getPropertyValueList ({ commit }, data) {
      return axios.get(baseURL, { params: data })
    }

    // async storePropertyType ({ commit }, data) {
    //   return axios.post(baseURL, data).then(response => {})
    // },
    // async attachPropertyType ({ commit }, data) {
    //   return axios.put(baseURL + '/' + data.id + '/attach', data).then(response => {})
    // }
  }
}
