import axios from 'axios'
import { eventBus } from '@/main'

const baseURL = process.env.VUE_APP_PRODUCT_SERVICE + '/api/admin/properties'

export default {
  namespaced: true,
  state: {
    is_processed: false,
    show_properties_panel: false, // Category
    show_products_properties_panel: false // Products
  },
  mutations: {
    setShowPropertiesPanel (state, value) {
      state.show_properties_panel = value === true
    },
    setShowProductsPropertiesPanel (state, value) {
      state.show_products_properties_panel = value === true
    }
  },
  actions: {
    storeProperty ({ state, commit }, data) {
      state.is_processed = true
      const promise = axios.post(baseURL, data)
      promise.then(r => {
        state.is_processed = false
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Обновлено',
          'Характеристики успешно обновлены!'
        )
      }).catch(e => {
        state.is_processed = false
        eventBus.$root.$emit(
          'send_notify',
          'error',
          'Ошибка',
          'Произошла ошибка!'
        )
      })
      return promise
    },
    storeBaseProperty ({ state, commit }, data) {
      state.is_processed = true
      const promise = axios.post(baseURL + '/base', data)
      promise.then(r => {
        state.is_processed = false
        eventBus.$root.$emit(
          'send_notify',
          'success',
          'Обновлено',
          'Характеристики успешно обновлены!'
        )
      }).catch(e => {
        state.is_processed = false
        eventBus.$root.$emit(
          'send_notify',
          'error',
          'Ошибка',
          'Произошла ошибка!'
        )
      })
      return promise
    }
  }
}
