<template>
  <v-navigation-drawer
    v-model="show_aside"
    app
    right
    temporary
    clipped
    width="800px"
  >
    <v-card flat>
      <v-toolbar
        color="rgba(0, 0, 0, 0)"
        theme="dark"
        flat
      >
        <v-btn icon @click="show_aside = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <div>Основные Свойства</div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col sm="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Тип
                  </th>
                  <th class="text-left">
                    Значение
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(item, index) in typeListComputed"
                  :key="item.id"
                >
                  <td>
                    <div class="mt-4 mb-4"><b>{{ item.name }}</b></div>
                    <v-divider class="mx-6"></v-divider>
                    <div class="mt-4 mb-4"><b>{{ item.name_uk }}</b></div>
                  </td>
                  <td style="padding: 15px">
                    <div v-if="selected_ids && selected_ids.length">
                    <v-combobox
                      v-model="item.value.value"
                      @keyup="searchValues(item.id, item.value.value)"
                      :items="values"
                      class="mb-1"
                      label="Значение RU"
                      item-text="value"
                      solo
                      dense
                      hide-details
                      @change="selectValueRU($event, index)"
                    />
                    <v-combobox
                      v-model="item.value.value_uk"
                      @keyup="searchValuesUK(item.id, item.value.value_uk)"
                      :items="values"
                      label="Значение UA"
                      item-text="value_uk"
                      solo
                      dense
                      hide-details
                      @change="selectValueUK($event, index)"
                    />
                    </div>
                    <div v-else>Количество товаров: {{item.products}}</div>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              v-if="selected_ids && selected_ids.length"
              @click="saveProductProperties"
              :disabled="is_processed"
              :loading="is_processed"
              color="primary"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'ProductPropertiesAsideComponent',
  data () {
    return {
      show_create_property_type: false,
      show_edit_property_type: false,
      show_delete_property_type: false,
      edited_property: null,
      timer: null,
      values: []
    }
  },
  computed: {
    ...mapGetters('products', ['selected_ids']),
    ...mapState('properties', ['show_products_properties_panel', 'is_processed']),
    ...mapState('propertyTypes', ['base_property_type_list']),
    show_aside: {
      get () {
        return this.show_products_properties_panel
      },
      set (v) {
        this.setShowProductsPropertiesPanel(v)
        if (v) {
          this.getBasePropertyTypeList()
        }
      }
    },
    typeListComputed: {
      get () {
        return this.base_property_type_list.map(item => ({
          ...item,
          value: item.value
            ? item.value
            : {
                value: null,
                value_uk: null
              }
        }))
      },
      set (v) {
        this.setBasePropertyTypeList(v)
      }
    }
  },
  methods: {
    ...mapActions('propertyTypes', [
      'getBasePropertyTypeList'
    ]),
    ...mapActions('properties', ['storeBaseProperty']),
    ...mapActions('propertyValues', ['getPropertyValueList']),
    ...mapMutations('properties', ['setShowProductsPropertiesPanel']),
    ...mapMutations('propertyTypes', ['setBasePropertyTypeList']),
    searchValues (typeId, search) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        const filters = {
          type_id: typeId,
          value: search
        }
        const promise = this.getPropertyValueList(filters)
        promise.then(r => {
          this.values = r.data.data
        })
      }, 100)
    },
    searchValuesUK (typeId, search) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        const filters = {
          type_id: typeId,
          value_uk: search
        }
        const promise = this.getPropertyValueList(filters)
        promise.then(r => {
          this.values = r.data.data
        })
      }, 100)
    },
    selectValueRU (v, index) {
      const currentItem = this.typeListComputed[index]
      if (v && typeof v === 'object') {
        currentItem.value.value_id = v.id
        currentItem.value.value = v.value
        currentItem.value.value_uk = v.value_uk
      } else {
        currentItem.value.value_id = null
        currentItem.value.value = v
        if (!currentItem.value.value_uk) {
          currentItem.value.value_uk = v
        }
      }
      this.typeListComputed[index] = currentItem
      this.typeListComputed = [...this.typeListComputed]
      this.values = []
    },

    selectValueUK (v, index) {
      const currentItem = this.typeListComputed[index]
      if (v && typeof v === 'object') {
        currentItem.value.value_id = v.id
        currentItem.value.value = v.value
        currentItem.value.value_uk = v.value_uk
      } else {
        currentItem.value.value_id = null
        currentItem.value.value_uk = v
        if (!currentItem.value.value) {
          currentItem.value.value = v
        }
      }
      this.typeListComputed[index] = currentItem
      this.typeListComputed = [...this.typeListComputed]
      this.values = []
    },
    saveProductProperties () {
      const productIds = this.selected_ids
      const typeData = []

      this.typeListComputed.forEach(item => {
        if (item.value.value_uk || item.value.value) {
          typeData.push({
            type_id: item.id,
            value: {
              value_id: item.value.value_id,
              value: item.value.value,
              value_uk: item.value.value_uk
            }
          })
        }
      })

      const data = {
        products: productIds,
        types: typeData
      }
      this.storeBaseProperty(data)
    },
    editProperty (item) {
      this.edited_property = item
      this.show_edit_property_type = true
    },
    showDeletePropertyType (item) {
      this.edited_property = item
      this.show_delete_property_type = true
    }
  },
  created () {
  }
}
</script>

<style scoped>

</style>
